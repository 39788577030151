import React from 'react';
import { Link, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { FaSearch, FaLongArrowAltRight } from 'react-icons/fa';
import Select from 'react-select';
import { between, lighten, darken } from 'polished';
import Img from 'gatsby-image';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import CategorySection from '../components/category-section';
import PostList from '../components/post-list';
import Thumbnail from '../components/thumbnail';
import Container from '../components/container';
import SEO from '../components/seo';

const HomepageHeaderSection = styled(HeaderSection)`
	min-height: 450px;

	@media (min-width: ${props => props.theme.breakpoints.md}) {
		height: 80vh;
		min-height: 600px;
	}
`;

const VideoWrapper = styled.div`
	display: none;
	overflow: hidden;
	max-height: 100%;

	video {
		width: 100%;
	}

	@media (min-width: ${props => props.theme.breakpoints.md}) {
		display: block;
	}
}
`;

const StyledForm = styled.form`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: ${between('10px', '23px')};
	margin-top: ${between('10px', '46px')};
	text-align: left;
	font-size: 14px;

	label {
		color: ${props => props.theme.colors.warm_grey_200};
		margin-bottom: 4px;
		display: inline-block;
	}

	.select {
		color: ${props => props.theme.colors.warm_grey_900};
	}

	.search {
		background: ${props => props.theme.colors.golden_500};
		background: linear-gradient(to bottom, ${props => props.theme.colors.golden_500 && lighten(0.08, props.theme.colors.golden_500)} 0%, ${props => props.theme.colors.golden_500} 100%);
		border: none;
		align-self: end;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
		padding: 14px 0 11px;
		cursor: pointer;
		transition: all 0.3s;

		svg {
			width: 14px;
			margin-bottom: -2px;
			margin-left: 7px;
		}

		&:hover,
		&:focus {
			background: ${props => props.theme.colors.golden_500 && darken(0.05, props.theme.colors.golden_500)};
			background: linear-gradient(to bottom, ${props => props.theme.colors.golden_500} 0%, ${props => props.theme.colors.golden_500 && darken(0.08, props.theme.colors.golden_500)} 100%);
			outline: none;
		}
	}
`;

const styledSelect = { menu: styles => ({ ...styles, zIndex: 250 }) };

class IndexPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			towns: [],
			postsByCategory: this.props.pageContext.posts,
			selectedCategory: null,
			selectedTown: null,
			isTownsDropdownVisible: false,
		};

		props.data.allMysqlCategory.edges.forEach(({ node }, i) => {
			const obj = { value: node.slug, label: node.label_nominative };
			if (i === 0) this.state.selectedCategory = obj;
			this.state.categories.push(obj);
		});

		props.data.allMysqlTown.edges.forEach(({ node }, i) => {
			const obj = { value: node.slug, label: node.label_nominative };
			if (i === 0) this.state.selectedTown = obj;
			this.state.towns.push(obj);
		});

		this.hideTownsDropdown = this.hideTownsDropdown.bind(this);
		this.handleTownChange = this.handleTownChange.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.handleTownInputChange = this.handleTownInputChange.bind(this);
		this.search = this.search.bind(this);
	};

	componentDidMount() {
		if (typeof window !== 'undefined' && window && window.localStorage) {
			let selectedTown = window.localStorage.getItem('lastUserTown');
			if (selectedTown) {
				try {
					selectedTown = JSON.parse(selectedTown);
					this.setState({ selectedTown });
				} catch (e) { }
			}

			let selectedCategory = window.localStorage.getItem('lastUserCategory');
			if (selectedCategory) {
				try {
					selectedCategory = JSON.parse(selectedCategory);
					this.setState({ selectedCategory });
				} catch (e) { }
			}
		}
	}

	hideTownsDropdown() {
		this.setState({ isTownsDropdownVisible: false });
	};

	handleTownChange(selectedTown) {
		this.setState({ selectedTown });
		if (typeof window !== 'undefined' && window && window.localStorage) window.localStorage.setItem('lastUserTown', JSON.stringify(selectedTown));
		this.hideTownsDropdown();
	};

	handleCategoryChange(selectedCategory) {
		this.setState({ selectedCategory });
		if (typeof window !== 'undefined' && window && window.localStorage) window.localStorage.setItem('lastUserCategory', JSON.stringify(selectedCategory));
	};

	handleTownInputChange(query, { action }) {
		if (action === 'input-change') this.setState({ isTownsDropdownVisible: query.length > 1 });
	};

	search(event) {
		event.preventDefault();
		navigate(`/${this.state.selectedCategory.value}/${this.state.selectedTown.value}/`);
	};

	render() {
		return (
			<Page>
				<SEO title="Zorganizuj swoje wesele" />

				<HomepageHeaderSection>
					<VideoWrapper>
						<video autoPlay loop muted>
							<source src="/footage-optimized.webm" type="video/webm" />
						</video>
					</VideoWrapper>

					<HeaderFront>
						<Navigation />
						<Container>
							<HeaderHeading>Zorganizuj swoje wesele</HeaderHeading>

							<StyledForm onSubmit={this.search}>
								<div className="field">
									<label htmlFor="categories">Kategoria</label>
									<Select id="categories" options={this.state.categories} value={this.state.selectedCategory} className="select" placeholder="Czego szukasz?" onChange={this.handleCategoryChange} isSearchable={false} styles={styledSelect} theme={theme => ({
										...theme,
										borderRadius: 0,
										zIndex: 300,
										colors: {
											...theme.colors,
											primary25: 'hsl(43, 13%, 90%)',
											primary50: 'hsl(43, 13%, 90%)',
											primary: 'hsl(41, 8%, 61%)',
										},
									})} components={
										{ IndicatorSeparator: () => null }
									} />
								</div>

								<div className="field">
									<label htmlFor="towns">Miasto</label>
									<Select id="towns" options={this.state.towns} value={this.state.selectedTown} className="select" placeholder="Gdzie?" noOptionsMessage={() => 'Nie znaleziono miasta...'} menuIsOpen={this.state.isTownsDropdownVisible} onChange={this.handleTownChange} onBlur={this.hideTownsDropdown} onInputChange={this.handleTownInputChange} styles={styledSelect} theme={theme => ({
										...theme,
										borderRadius: 0,
										zIndex: 300,
										colors: {
											...theme.colors,
											primary25: 'hsl(43, 13%, 90%)',
											primary50: 'hsl(43, 13%, 90%)',
											primary: 'hsl(41, 8%, 61%)',
										},
									})} components={
										{
											DropdownIndicator: () => null,
											IndicatorSeparator: () => null,
										}
									} />
								</div>

								<button type="submit" className="search">
									<span className="label">Szukaj</span>
									<FaSearch />
								</button>
							</StyledForm>
						</Container>
					</HeaderFront>
				</HomepageHeaderSection>

				{this.props.data.allMysqlCategory.edges.map(({ node }, index) => (
					<CategorySection key={index} className="slant--top slant--bottom">
						<div className="split">
							<div className="content">
								<h2>
									<Link to={`/${node.slug}/`}>{node.label_nominative}</Link>
								</h2>
								<p>
									{node.description}
								</p>
								<Link to={`/${node.slug}/`}>
									Zobacz wszystkie oferty <FaLongArrowAltRight />
								</Link>
							</div>

							<Link to={`/${node.slug}/`} className="aside" tabIndex="-1">
								<Img fluid={this.props.data[`cat${node.uid}`].childImageSharp.fluid} loading="lazy" alt={node.label_nominative} />
							</Link>
						</div>

						<Container>
							<PostList>
								{this.state.postsByCategory[node.slug].map(post => (
									<Thumbnail key={post.node.slug} post={post.node} />
								))}
							</PostList>
						</Container>
					</CategorySection>
				))}
			</Page>
		);
	};
};

export default IndexPage;

export const query = graphql`
	query IndexPage {
		allMysqlTown {
			edges {
				node {
					slug
					label_nominative
				}
			}
		}

		allMysqlCategory {
			edges {
				node {
					uid
					slug
					label_nominative
					description
				}
			}
		}

		cat1: file(relativePath: { eq: "splash-music.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat2: file(relativePath: { eq: "splash-decor.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat3: file(relativePath: { eq: "splash-fashion-beauty.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat4: file(relativePath: { eq: "splash-foto-video.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat5: file(relativePath: { eq: "splash-gastronomy.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat6: file(relativePath: { eq: "splash-cars.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat7: file(relativePath: { eq: "splash-wedding-venues.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat8: file(relativePath: { eq: "splash-miscellaneous.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
